import * as Actions from '@/store/actions/types';
export function storeUserAnalyticDashboardsSettings(state, { payload: analyticDashboardsSettings }) {
    return Object.assign(Object.assign({}, state), { analyticDashboardsSettings });
}
export function storeUserAnalyticDashboardsFilters(state, { payload: analyticsSavedFilters }) {
    return Object.assign(Object.assign({}, state), { analyticsSavedFilters });
}
export function storeUserAnalyticDashboardsLocationSettings(state, { payload: analyticsSelectedLocation }) {
    return Object.assign(Object.assign({}, state), { analyticsSelectedLocation });
}
export function storeSetUserSettings(state, { payload: UserSettingsState }) {
    return Object.assign(Object.assign({}, state), UserSettingsState);
}
export default (state = {}, action) => {
    switch (action.type) {
        case Actions.STORE_SET_USER_SETTINGS:
            return storeSetUserSettings(state, action);
        case Actions.STORE_USER_ANALYTIC_DASHBOARDS_SETTINGS:
            return storeUserAnalyticDashboardsSettings(state, action);
        case Actions.STORE_USER_ANALYTIC_DASHBOARDS_FILTERS:
            return storeUserAnalyticDashboardsFilters(state, action);
        case Actions.STORE_USER_ANALYTIC_DASHBOARDS_LOCATION_SETTINGS:
            return storeUserAnalyticDashboardsLocationSettings(state, action);
        default:
            return state;
    }
};
