import * as Utils from './utils';
import { API_HOST, API_PATH, API_PORT, DYNAMIC_API, SEO_API_HOST, } from '@/config/global';
import { ModelType } from '@/constants';
export const BASE_URL = `http${window.location.protocol === 'https:' ? 's' : ''}://${DYNAMIC_API ? window.location.host : API_HOST}${API_PORT ? `:${API_PORT}` : ''}`;
export const REFAPP_URL = 'http://refapp.groupbycloud.com';
export const TEMPLATE_IMAGES_URL = `${BASE_URL}/templateImages`;
export const JSON_HEADERS = { 'Content-Type': 'application/json' };
export var StreamAdmin;
(function (StreamAdmin) {
    StreamAdmin.API_URL = `${BASE_URL}/api/v2`;
    StreamAdmin.COLLECTIONS_PATH = 'collections';
})(StreamAdmin || (StreamAdmin = {}));
export var Admin;
(function (Admin) {
    Admin.API_URL = `${BASE_URL}/admin/v2`;
    Admin.COLLECTIONS_PATH = 'collections';
})(Admin || (Admin = {}));
export var Images;
(function (Images) {
    Images.API_URL = `${BASE_URL}/templateImages`;
    Images.UPLOAD_PATH = 'upload';
})(Images || (Images = {}));
export var Merchandising;
(function (Merchandising) {
    Merchandising.API_URL = `${BASE_URL}${API_PATH || ''}`;
    Merchandising.ADMIN_PATH = 'admin';
    Merchandising.AREA_PATH = `${Merchandising.ADMIN_PATH}/area`;
    Merchandising.AREA_PROMOTE_PATH = `${Merchandising.AREA_PATH}/_promote`;
    Merchandising.USER_PATH = `${Merchandising.ADMIN_PATH}/user`;
    Merchandising.AUTH_VALIDATION_PATH = `${Merchandising.USER_PATH}/_validate`;
    Merchandising.PASSWORD_VALIDATION_PATH = 'password/validate';
    Merchandising.KEY_PATH = `${Merchandising.ADMIN_PATH}/key`;
    Merchandising.LOGIN_PATH = 'login';
    Merchandising.PRODUCT_ATTRIBUTES_PATH = 'autocomplete/fields';
    Merchandising.PRODUCT_ATTRIBUTE_VALUES_PATH = 'autocomplete/values';
    Merchandising.SEARCH_PREVIEW_PATH = 'proxy/search';
})(Merchandising || (Merchandising = {}));
export var SearchForRetail;
(function (SearchForRetail) {
    SearchForRetail.API_URL = Utils.getAPIUrl(window.location.hostname);
    SearchForRetail.INGEST_API_URL = Utils.getIngestAPIUrl(window.location.hostname);
    SearchForRetail.SEARCH_API_URL = Utils.getSearchAPIUrl(window.location.hostname);
    SearchForRetail.CATEGORIES_API_URL = Utils.getCategoriesAPIUrl(window.location.hostname);
    SearchForRetail.RECS_API_URL = Utils.getRecsAPIUrl(window.location.hostname);
    SearchForRetail.CONFIG_PATH = 'config';
    SearchForRetail.ADMIN_PATH = 'admin';
    SearchForRetail.AREA_PATH = `${SearchForRetail.ADMIN_PATH}/area`;
    SearchForRetail.USER_PATH = 'user';
    SearchForRetail.PERMISSION_ROLE_PATH = `${SearchForRetail.USER_PATH}/roles`;
    SearchForRetail.TAG_PATH = `${SearchForRetail.ADMIN_PATH}/tag`;
    SearchForRetail.LOGIN_PATH = 'login';
    SearchForRetail.AUTH_VALIDATION_PATH = 'oauth/access_token';
    SearchForRetail.TENANT_PATH = 'tenant';
    SearchForRetail.SETTINGS_PATH = 'settings';
    SearchForRetail.AUDIT_LOG_PATH = 'audit-log';
    SearchForRetail.ZONE_PATH = 'zone';
    SearchForRetail.PROMOTE_PATH = 'promote';
    SearchForRetail.PROMOTION_PATH = 'promotion';
    SearchForRetail.ANALYTICS_PATH = 'analytics';
    SearchForRetail.RELEASE_NOTES_PATH = 'release-notes/storage';
})(SearchForRetail || (SearchForRetail = {}));
export const Seo = {
    SITEMAP_API: `${SEO_API_HOST}/api/sitemaps`,
    KEYWORDS_API: `${SEO_API_HOST}/api/keywords`,
};
export function s4rPermissionRole(token) {
    return {
        find: Utils.authenticated(token, s4rFindPermissionRoles),
    };
}
export function s4rValidate(refreshToken) {
    return {
        url: `${SearchForRetail.API_URL}/${SearchForRetail.AUTH_VALIDATION_PATH}`,
        headers: Object.assign(Object.assign({}, JSON_HEADERS), Utils.buildCustomerHeaders()),
        expiry: false,
        skipCache: true,
        body: {
            'refresh_token': refreshToken,
            'grant_type': 'refresh_token',
        },
        method: 'POST',
    };
}
export function s4rFindPermissionRoles() {
    return s4rFind(SearchForRetail.PERMISSION_ROLE_PATH);
}
export function auth(token) {
    return {
        s4rValidate: Utils.authenticated(token, s4rValidate),
        searchForRetail: {
            [ModelType.PERMISSION_ROLE]: s4rPermissionRole(token),
        },
    };
}
export default { auth };
export function s4rFind(path, parse, skipCache) {
    return {
        parse,
        skipCache,
        url: `${SearchForRetail.API_URL}/${path}`,
        headers: Utils.buildCustomerHeaders(),
    };
}
