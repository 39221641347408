import moize from 'moize';
import { ModelType } from '@/constants';
export const GLOBAL_MODEL_TYPES = [
    ModelType.AREA,
    ModelType.AUDIT_LOG,
    ModelType.PERMISSION_ROLE,
    ModelType.TAG,
    ModelType.TEMPLATE,
    ModelType.USER,
    ModelType.FILTER_SETS,
    ModelType.PRODUCT_RECOMMENDATIONS,
    ModelType.ATTRIBUTES,
    ModelType.INGESTION_TASKS,
    ModelType.SITE,
    ModelType.AUTOCOMPLETE,
    ModelType.RECS_MODEL,
    ModelType.CATEGORIES,
    ModelType.IMAGE_PATH,
    ModelType.KEYWORD_SUGGESTIONS,
    ModelType.SITEMAP,
];
export const ADMIN_MODEL_TYPES = [
    ModelType.TEMPLATE,
    ModelType.TAG,
    ModelType.AREA,
    ModelType.USER,
    ModelType.INGESTION_TASKS,
    ModelType.AUTOCOMPLETE,
    ModelType.CATEGORIES,
];
export const isGlobalModelType = moize((modelType) => GLOBAL_MODEL_TYPES.includes(modelType));
export const isAdminModelType = moize((modelType) => ADMIN_MODEL_TYPES.includes(modelType));
