import { __awaiter } from "tslib";
import qs from 'qs';
import { SearchForRetail } from '@/client/api';
import { s4rAuthenticated } from '@/client/utils';
import { parseGetSitesResponse } from '@/search-for-retail/site/site.parsers';
export const getSiteShortList = (token, collection) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield s4rAuthenticated(token, {
        url: `${SearchForRetail.API_URL}/collections/${collection}/siteFilter/short`,
        method: 'GET',
    });
    return response.result;
});
export const getSiteList = (token, collection, { page, perPage: size, sort, filter, }, options) => __awaiter(void 0, void 0, void 0, function* () {
    const { propertyName, direction } = sort !== null && sort !== void 0 ? sort : {};
    const queryParams = qs.stringify({
        page,
        size,
        sort: propertyName,
        order: direction,
        name: filter === null || filter === void 0 ? void 0 : filter.name,
    });
    const response = yield s4rAuthenticated(token, Object.assign({ url: `${SearchForRetail.API_URL}/collections/${collection}/siteFilter?${queryParams}`, method: 'GET' }, options));
    return parseGetSitesResponse(response.result);
});
export const createSite = (token, collection, data) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield s4rAuthenticated(token, {
        url: `${SearchForRetail.API_URL}/collections/${collection}/siteFilter`,
        method: 'POST',
        body: data,
    });
    return [response.result, response.headers['x-groupby-location-page'] || null];
});
export const getSiteById = (token, collection, id) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield s4rAuthenticated(token, {
        url: `${SearchForRetail.API_URL}/collections/${collection}/siteFilter/${id}`,
        method: 'GET',
        skipCache: true,
    });
    return response.result;
});
export const updateSite = (token, collection, data) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield s4rAuthenticated(token, {
        url: `${SearchForRetail.API_URL}/collections/${collection}/siteFilter/${data.id}`,
        method: 'PUT',
        body: data,
    });
    return response.result;
});
export const deleteSite = (token, collection, id) => __awaiter(void 0, void 0, void 0, function* () {
    yield s4rAuthenticated(token, {
        url: `${SearchForRetail.API_URL}/collections/${collection}/siteFilter/${id}`,
        method: 'DELETE',
        skipCache: true,
    });
});
