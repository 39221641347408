import { combineReducers } from 'redux';
import { createEnrichReducers, ENRICH_REDUX_SLICE_KEY } from '@groupby/enrich-console';
import auth from './auth';
import cache from './cache';
import merchandising from './merchandising';
import notifications from './notifications';
import session from './session';
import ui from './ui';
import rule from './rule.reducers';
import releaseNotes from './release-notes.reducers';
import attributesShort from './attribute-short';
import collections from './collection';
import userSettings from './user-settings';
import { REF_APP_SLICE_KEY, refAppReducer } from '@/search-for-retail/evaluate';
const enrichReducer = createEnrichReducers();
export default function createReducers() {
    return combineReducers({
        auth,
        cache,
        merchandising,
        notifications,
        session,
        ui,
        rule,
        releaseNotes,
        attributesShort,
        collections,
        userSettings,
        [REF_APP_SLICE_KEY]: refAppReducer,
        [ENRICH_REDUX_SLICE_KEY]: enrichReducer,
    });
}
