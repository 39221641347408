import { __awaiter } from "tslib";
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useActiveArea, useActiveSite, useActiveUserSelector, useAreasSelector, useCollectionName, useCollectionsSelector, useFindAreasForCollection, useGetConfigPageType, useUserSettings, } from '@/store/selectors';
import { addError, addSuccess } from '@/store/actions/creators';
import { updateCurrentUserSettings } from '@/search-for-retail/user/user.client';
import { storeUserSettings } from '@/store/actions/creators/user-settings';
import { useAuthToken } from '@/auth/hooks/authentication';
import { useGoToNewArea, useIsHomePage } from '@/routing/hooks';
import * as ActionCreators from '@/store/actions/creators';
import { SearchForRetail } from '@/constants';
import { ContextSelectors } from './context-selectors.component';
import { findArea, hasArea, useSetDefaultCollectionAndArea } from './utils';
import { logError } from '@/utils/logger';
export const ContextSelectorsContainer = () => {
    const dispatch = useDispatch();
    const goToNewArea = useGoToNewArea();
    const isHomePage = useIsHomePage();
    const activeCollectionName = useCollectionName();
    const activeAreaName = useActiveArea();
    const activeSite = useActiveSite();
    const collections = useCollectionsSelector();
    const activeUser = useActiveUserSelector();
    const areas = useAreasSelector();
    const token = useAuthToken();
    const settings = useUserSettings();
    const configType = useGetConfigPageType();
    const findAreasForCollection = useFindAreasForCollection();
    const [areasForSelectedCollection, setAreasForSelectedCollection] = useState(null);
    const [selectedAreaName, setSeletedAreaName] = useState(activeAreaName);
    const [selectedCollection, setSelectedCollection] = useState(activeCollectionName && (collections === null || collections === void 0 ? void 0 : collections.length)
        ? collections.find(({ collection }) => collection === activeCollectionName)
        : undefined);
    const [selectedSite, setSelectedSite] = useState(activeSite);
    const [selectedArea, setSelectedArea] = useState(findArea({
        areaName: activeAreaName,
        areas: areasForSelectedCollection,
        collection: selectedCollection,
    }));
    useEffect(() => {
        if ((selectedCollection === null || selectedCollection === void 0 ? void 0 : selectedCollection.collection) !== activeCollectionName || (selectedArea === null || selectedArea === void 0 ? void 0 : selectedArea.name) !== activeAreaName) {
            const newSelectedCollection = activeCollectionName && (collections === null || collections === void 0 ? void 0 : collections.length)
                ? collections.find(({ collection }) => collection === activeCollectionName)
                : undefined;
            setSelectedCollection(newSelectedCollection);
            if (newSelectedCollection && activeAreaName) {
                const filteredAreas = findAreasForCollection(newSelectedCollection === null || newSelectedCollection === void 0 ? void 0 : newSelectedCollection.collection, areas);
                const newSelectedArea = findArea({
                    areaName: activeAreaName,
                    areas: filteredAreas,
                    collection: newSelectedCollection,
                });
                if (newSelectedArea) {
                    setSelectedArea(newSelectedArea);
                    setSeletedAreaName(newSelectedArea.name);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        activeCollectionName,
        findAreasForCollection,
        collections,
        areas,
        activeAreaName,
    ]);
    const { defaultArea, defaultCollection } = useSetDefaultCollectionAndArea({
        setSeletedAreaName,
        setSelectedArea,
        setSelectedCollection,
        selectedCollection: selectedCollection === null || selectedCollection === void 0 ? void 0 : selectedCollection.collection,
    });
    // setting the valid areas based on the collection
    useEffect(() => {
        if (activeUser && (selectedCollection === null || selectedCollection === void 0 ? void 0 : selectedCollection.id) && (areas === null || areas === void 0 ? void 0 : areas.length)) {
            const filteredAreas = findAreasForCollection(selectedCollection === null || selectedCollection === void 0 ? void 0 : selectedCollection.collection, areas);
            setAreasForSelectedCollection(filteredAreas);
        }
    }, [activeUser, areas, selectedCollection, findAreasForCollection]);
    // setting selected area
    useEffect(() => {
        if (areasForSelectedCollection && !(areasForSelectedCollection === null || areasForSelectedCollection === void 0 ? void 0 : areasForSelectedCollection.length)) {
            setSelectedArea(undefined);
        }
        else if (areasForSelectedCollection && selectedCollection.id) {
            if (hasArea(areasForSelectedCollection, selectedArea)) {
                // the selected area is valid for this collection, do nothing
                return;
            }
            const foundArea = findArea({
                areaName: selectedAreaName,
                areas: areasForSelectedCollection,
                collection: selectedCollection,
            });
            setSelectedArea(foundArea);
        }
    }, [selectedAreaName, selectedArea, areasForSelectedCollection, selectedCollection]);
    const handleSelectCollection = (collection) => {
        setSelectedSite(null);
        dispatch(ActionCreators.switchSite(null));
        setSelectedCollection(collection);
    };
    const handleSelectArea = (area) => setSelectedArea(area);
    const isPageAreaDependent = configType === SearchForRetail.PageType.AREA;
    const isPageSiteDependent = configType === SearchForRetail.PageType.SITE;
    const collectionApplied = (selectedCollection === null || selectedCollection === void 0 ? void 0 : selectedCollection.collection) === activeCollectionName;
    const areaApplied = (selectedArea === null || selectedArea === void 0 ? void 0 : selectedArea.name) === activeAreaName;
    const siteApplied = (selectedSite === null || selectedSite === void 0 ? void 0 : selectedSite.name) === (activeSite === null || activeSite === void 0 ? void 0 : activeSite.name);
    const allApplied = isPageSiteDependent ? collectionApplied && siteApplied : collectionApplied && areaApplied;
    const isApplyButtonDisabled = !selectedCollection
        || (isPageAreaDependent && !selectedArea)
        || (isPageSiteDependent && !selectedSite)
        || (collectionApplied && !isPageAreaDependent && !isPageSiteDependent)
        || allApplied;
    const handleApplyClicked = useCallback(() => {
        if (!(selectedCollection === null || selectedCollection === void 0 ? void 0 : selectedCollection.collection)) {
            return;
        }
        dispatch(ActionCreators.setCollectionName(selectedCollection.collection));
        if (isPageAreaDependent) {
            dispatch(ActionCreators.switchArea(selectedArea.name));
        }
        if (isPageSiteDependent) {
            dispatch(ActionCreators.switchSite(selectedSite));
        }
        if (!isHomePage) {
            goToNewArea(selectedCollection.collection, selectedArea === null || selectedArea === void 0 ? void 0 : selectedArea.name);
        }
    }, [
        selectedCollection === null || selectedCollection === void 0 ? void 0 : selectedCollection.collection,
        selectedArea === null || selectedArea === void 0 ? void 0 : selectedArea.name,
        selectedSite,
        dispatch,
        isHomePage,
        goToNewArea,
        isPageAreaDependent,
        isPageSiteDependent,
    ]);
    const handleSetAsDefaultClicked = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { defaultArea, defaultCollection } = yield updateCurrentUserSettings(token, {
                preferredLanguage: settings.preferredLanguage,
                defaultCollection: selectedCollection === null || selectedCollection === void 0 ? void 0 : selectedCollection.collection,
                defaultArea: selectedArea === null || selectedArea === void 0 ? void 0 : selectedArea.name,
            });
            dispatch(storeUserSettings({ defaultArea, defaultCollection }));
            dispatch(addSuccess({ key: 'USER_SETTINGS_UPDATE_SUCCESS' }));
            if (!isApplyButtonDisabled) {
                handleApplyClicked();
            }
        }
        catch (e) {
            const errorMessage = 'USER_SETTINGS_UPDATE_ERROR';
            logError(errorMessage, e);
            dispatch(addError({ key: errorMessage }));
        }
    }), [token, selectedArea, selectedCollection, settings, dispatch, handleApplyClicked, isApplyButtonDisabled]);
    return (<ContextSelectors collections={collections} defaultCollection={defaultCollection} selectedCollection={selectedCollection} onSelectCollection={handleSelectCollection} areas={areasForSelectedCollection !== null && areasForSelectedCollection !== void 0 ? areasForSelectedCollection : []} defaultArea={defaultArea} selectedArea={selectedArea} onSelectArea={handleSelectArea} selectedSite={selectedSite} onSelectSite={setSelectedSite} isApplyButtonDisabled={isApplyButtonDisabled} handleApplyClicked={handleApplyClicked} handleSetAsDefaultClicked={handleSetAsDefaultClicked}/>);
};
export default ContextSelectorsContainer;
