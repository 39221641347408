import React, { useEffect, useState } from 'react';
import { useActiveCollection, useActiveSite } from '@/store/selectors';
import { InitialSelectionPage } from '@/search-for-retail/generic/page-context-selection';
export const SitePageWrapper = ({ children, titleMessageKey }) => {
    const activeCollection = useActiveCollection();
    const site = useActiveSite();
    const [siteIsValid, setSiteIsValid] = useState();
    useEffect(() => {
        if (siteIsValid)
            return;
        if (site === null || site === void 0 ? void 0 : site.id) {
            setSiteIsValid(true);
        }
    }, [activeCollection, siteIsValid, site]);
    return (<React.Fragment>
      {!(site === null || site === void 0 ? void 0 : site.id) || !siteIsValid
        ? <InitialSelectionPage titleMessageKey={titleMessageKey} selectionMessageKey='SELECT_COLLECTION_AND_SITE_PAGE_TEXT'/>
        : children}
    </React.Fragment>);
};
export default SitePageWrapper;
