import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
export function userSettingsStoreSelector({ userSettings }) {
    return userSettings || {};
}
export const analyticDashboardsSettingsSelector = createSelector(userSettingsStoreSelector, (settings) => (settings === null || settings === void 0 ? void 0 : settings.analyticDashboardsSettings) || '{}');
export const analyticDashboardsFiltersSelector = createSelector(userSettingsStoreSelector, (settings) => (settings === null || settings === void 0 ? void 0 : settings.analyticsSavedFilters) || '{}');
export const analyticDashboardsLocationSettingsSelector = createSelector(userSettingsStoreSelector, (settings) => settings === null || settings === void 0 ? void 0 : settings.analyticsSelectedLocation);
export const useUserSettingsStore = () => useSelector(userSettingsStoreSelector);
export const useAnalyticsDashboardsSettingsSelector = () => useSelector(analyticDashboardsSettingsSelector);
export const useAnalyticDashboardsFiltersSelector = () => useSelector(analyticDashboardsFiltersSelector);
export const useAnalyticsDashboardsLocationSettingsSelector = () => useSelector(analyticDashboardsLocationSettingsSelector);
