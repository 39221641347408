import defaults from '../../webpack/defaults';
import { extractCustomerName } from './utils';
const GLOBAL_API_HOST = typeof API_HOST === 'undefined' ? defaults.API_HOST : API_HOST;
const GLOBAL_API_PATH = typeof API_PATH === 'undefined' ? defaults.API_PATH : API_PATH;
const GLOBAL_API_PORT = typeof API_PORT === 'undefined' ? process.env.API_PORT : API_PORT;
const GLOBAL_S4R_API_PREFIX = typeof S4R_API_PREFIX === 'undefined' ? defaults.S4R_API_PREFIX : S4R_API_PREFIX;
const GLOBAL_ENRICH_API_PREFIX = typeof ENRICH_API_PREFIX === 'undefined' ? defaults.ENRICH_API_PREFIX : ENRICH_API_PREFIX;
const GLOBAL_S4R_API_ENV = typeof S4R_API_ENV === 'undefined' ? defaults.S4R_API_ENV : S4R_API_ENV;
const GLOBAL_APP_VERSION = typeof APP_VERSION === 'undefined' ? process.env.APP_VERSION : APP_VERSION;
const GLOBAL_DYNAMIC_API = typeof DYNAMIC_API === 'undefined' ? defaults.DYNAMIC_API : DYNAMIC_API;
const GLOBAL_E2E_COOKIE_AUTHENTICATON = typeof E2E_COOKIE_AUTHENTICATON === 'undefined'
    ? process.env.E2E_COOKIE_AUTHENTICATON : E2E_COOKIE_AUTHENTICATON;
const GLOBAL_NODE_ENV = typeof NODE_ENV === 'undefined' ? process.env.NODE_ENV : NODE_ENV;
const GLOBAL_SKIP_AUTHENTICATION = typeof SKIP_AUTHENTICATION === 'undefined'
    ? process.env.SKIP_AUTHENTICATION : SKIP_AUTHENTICATION;
const GLOBAL_TARGET_CUSTOMER = typeof TARGET_CUSTOMER === 'undefined'
    ? process.env.TARGET_CUSTOMER
    : TARGET_CUSTOMER || extractCustomerName(window.location.hostname);
const GLOBAL_SEO_API_HOST = typeof SEO_API_HOST === 'undefined' ? defaults.SEO_API_HOST : SEO_API_HOST;
const IS_PRODUCTION_ENV = GLOBAL_NODE_ENV === 'production';
const IS_TEST_ENV = GLOBAL_NODE_ENV === 'test';
const IS_STAGING_ENV = window && window.location && /^(cc|sr)-qa\d\.groupbycloud\.com$/.test(window.location.hostname);
const IS_DEV_ENV = window && window.location && /^[\w-]+\.dev\.groupbycloud\.com$/.test(window.location.hostname);
const IS_LOCAL_ENV = window && window.location && window.location.hostname.startsWith('localhost');
const IS_NON_PUBLIC_ENV = IS_STAGING_ENV || IS_LOCAL_ENV || IS_DEV_ENV;
const IS_LOWER_ENV = (window === null || window === void 0 ? void 0 : window.location) && /^[\w-]+\.[\w-]+-lo\.groupbycloud\.com$/.test(window.location.hostname);
export { GLOBAL_API_HOST as API_HOST, GLOBAL_NODE_ENV as NODE_ENV, GLOBAL_API_PATH as API_PATH, GLOBAL_API_PORT as API_PORT, GLOBAL_S4R_API_PREFIX as S4R_API_PREFIX, GLOBAL_ENRICH_API_PREFIX as ENRICH_API_PREFIX, GLOBAL_S4R_API_ENV as S4R_API_ENV, GLOBAL_APP_VERSION as APP_VERSION, GLOBAL_DYNAMIC_API as DYNAMIC_API, GLOBAL_E2E_COOKIE_AUTHENTICATON as E2E_COOKIE_AUTHENTICATON, GLOBAL_SKIP_AUTHENTICATION as SKIP_AUTHENTICATION, GLOBAL_TARGET_CUSTOMER as TARGET_CUSTOMER, // prefer getTenantName() from src/utils/tenant.ts
GLOBAL_SEO_API_HOST as SEO_API_HOST, IS_PRODUCTION_ENV, IS_TEST_ENV, IS_STAGING_ENV, IS_DEV_ENV, IS_LOCAL_ENV, IS_NON_PUBLIC_ENV, IS_LOWER_ENV, };
