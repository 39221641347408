export var DialogActions;
(function (DialogActions) {
    let Area;
    (function (Area) {
        Area["Duplicate"] = "DUPLICATE_AREA";
        Area["Delete"] = "DELETE_AREA";
        Area["CopyConfigurations"] = "COPY_CONFIGURATIONS";
        Area["ViewAuditLog"] = "VIEW_AUDIT_LOG";
    })(Area = DialogActions.Area || (DialogActions.Area = {}));
    let BiasingStrategy;
    (function (BiasingStrategy) {
        BiasingStrategy["Delete"] = "DELETE_BIASING_STRATEGY";
        BiasingStrategy["Promote"] = "PROMOTE_BIASING_STRATEGY";
        BiasingStrategy["NewPromote"] = "NEW_PROMOTE_BIASING_STRATEGY";
        BiasingStrategy["ViewAuditLog"] = "VIEW_AUDIT_LOG";
    })(BiasingStrategy = DialogActions.BiasingStrategy || (DialogActions.BiasingStrategy = {}));
    let DynamicNavigation;
    (function (DynamicNavigation) {
        DynamicNavigation["Delete"] = "DELETE_DYNAMIC_NAVIGATION";
        DynamicNavigation["MoveAboveBelow"] = "MOVE_ABOVE_BELOW";
        DynamicNavigation["MoveHere"] = "MOVE_DYNAMIC_NAVIGATION_HERE";
        DynamicNavigation["Promote"] = "PROMOTE_DYNAMIC_NAVIGATION";
        DynamicNavigation["ViewAuditLog"] = "VIEW_AUDIT_LOG";
    })(DynamicNavigation = DialogActions.DynamicNavigation || (DialogActions.DynamicNavigation = {}));
    let Redirect;
    (function (Redirect) {
        Redirect["Delete"] = "DELETE_REDIRECT";
        Redirect["Promote"] = "PROMOTE_REDIRECT";
        Redirect["MoveAboveBelow"] = "MOVE_ABOVE_BELOW";
        Redirect["ViewAuditLog"] = "VIEW_AUDIT_LOG";
    })(Redirect = DialogActions.Redirect || (DialogActions.Redirect = {}));
    let Rule;
    (function (Rule) {
        Rule["MoveHere"] = "MOVE_RULE_HERE";
        Rule["Delete"] = "DELETE_RULE";
        Rule["Promote"] = "PROMOTE_RULE";
        Rule["Promotion"] = "PROMOTION_RULE";
        Rule["BulkPromotion"] = "BULK_PROMOTION_RULE";
        Rule["MoveAboveBelow"] = "MOVE_ABOVE_BELOW";
        Rule["PromoteWithForce"] = "PROMOTE_WITH_FORCE";
        Rule["ViewAuditLog"] = "VIEW_AUDIT_LOG";
        Rule["BulkPromoteWithForce"] = "BULK_PROMOTE_WITH_FORCE";
    })(Rule = DialogActions.Rule || (DialogActions.Rule = {}));
    let User;
    (function (User) {
        User["Delete"] = "DELETE_USER";
        User["ViewAuditLog"] = "VIEW_AUDIT_LOG";
    })(User = DialogActions.User || (DialogActions.User = {}));
    let Tag;
    (function (Tag) {
        Tag["Delete"] = "DELETE_TAG";
        Tag["ViewAuditLog"] = "VIEW_AUDIT_LOG";
    })(Tag = DialogActions.Tag || (DialogActions.Tag = {}));
    let Zone;
    (function (Zone) {
        Zone[Zone["Delete"] = 0] = "Delete";
        Zone[Zone["Promote"] = 1] = "Promote";
        Zone[Zone["Reorder"] = 2] = "Reorder";
        Zone[Zone["Duplicate"] = 3] = "Duplicate";
        Zone[Zone["ViewAuditLog"] = 4] = "ViewAuditLog";
    })(Zone = DialogActions.Zone || (DialogActions.Zone = {}));
    let Template;
    (function (Template) {
        Template[Template["Delete"] = 0] = "Delete";
        Template[Template["ViewAuditLog"] = 1] = "ViewAuditLog";
    })(Template = DialogActions.Template || (DialogActions.Template = {}));
    let ProductRecommendations;
    (function (ProductRecommendations) {
        ProductRecommendations[ProductRecommendations["Delete"] = 0] = "Delete";
        ProductRecommendations[ProductRecommendations["Duplicate"] = 1] = "Duplicate";
        ProductRecommendations[ProductRecommendations["ViewAuditLog"] = 2] = "ViewAuditLog";
    })(ProductRecommendations = DialogActions.ProductRecommendations || (DialogActions.ProductRecommendations = {}));
    let LinguisticControls;
    (function (LinguisticControls) {
        LinguisticControls[LinguisticControls["BulkDeleteFailure"] = 0] = "BulkDeleteFailure";
        LinguisticControls[LinguisticControls["Delete"] = 1] = "Delete";
        LinguisticControls[LinguisticControls["BulkDelete"] = 2] = "BulkDelete";
    })(LinguisticControls = DialogActions.LinguisticControls || (DialogActions.LinguisticControls = {}));
    let FilterSet;
    (function (FilterSet) {
        FilterSet[FilterSet["Delete"] = 0] = "Delete";
        FilterSet[FilterSet["ViewAuditLog"] = 1] = "ViewAuditLog";
    })(FilterSet = DialogActions.FilterSet || (DialogActions.FilterSet = {}));
    let PinnedNavigations;
    (function (PinnedNavigations) {
        PinnedNavigations[PinnedNavigations["Delete"] = 0] = "Delete";
        PinnedNavigations[PinnedNavigations["Reorder"] = 1] = "Reorder";
    })(PinnedNavigations = DialogActions.PinnedNavigations || (DialogActions.PinnedNavigations = {}));
    let RecsModel;
    (function (RecsModel) {
        RecsModel[RecsModel["Delete"] = 0] = "Delete";
    })(RecsModel = DialogActions.RecsModel || (DialogActions.RecsModel = {}));
    let Categories;
    (function (Categories) {
        Categories[Categories["Delete"] = 0] = "Delete";
    })(Categories = DialogActions.Categories || (DialogActions.Categories = {}));
})(DialogActions || (DialogActions = {}));
// First and Last value of this list is used like default option for some tables. be careful.
export const tableRowsPerPageOptions = [24, 36, 120, 240, 360];
export const tableSearchApiRowsPerPageOptions = [40, 80, 120];
export const TABLE_DEFAULT_PAGINATION = {
    page: 1,
    perPage: tableRowsPerPageOptions[0],
};
