// const tenant = 'gbiqa';
const tenant = 'liverpool';
// const tenant = 'rscomponents';
const isLower = true;
const suffix = isLower ? '-lo' : '';
const apiEnv = `${tenant}${suffix}`;
export default {
    API_HOST: 'groupbycloud.com',
    API_PATH: '/admin/v2',
    ENRICH_API_PREFIX: 'https://api.enrich-lo.groupbycloud.com',
    // ENRICH_API_PREFIX: 'https://api.enrich.groupbycloud.com',
    CC_ENRICH_API_PREFIX: 'https://ccapi.enrich-lo.groupbycloud.com/ccapi',
    // CC_ENRICH_API_PREFIX: 'https://ccapi.enrich.groupbycloud.com/ccapi',
    DYNAMIC_API: false,
    LOCAL_S4R_API_PREFIX: 'http://localhost:8080',
    ENRICH_QA_API_SUBDOMAIN: 'enrich',
    SR_QA_API_SUBDOMAIN: 'sr-qa1',
    TARGET_CUSTOMER: '',
    QA_API_SUBDOMAIN: tenant,
    S4R_API_ENV: apiEnv,
    SEO_API_HOST: `https://seomanager.${apiEnv}.groupbycloud.com`,
    S4R_API_PREFIX: `https://ccapi.${apiEnv}.groupbycloud.com/ccapi`,
};
