import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const KeyTermGeographyLoadingIcon = (_a) => {
    var { withSearch } = _a, props = __rest(_a, ["withSearch"]);
    return (<SvgIcon preserveAspectRatio="none" viewBox="0 0 390 338" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    {withSearch && (<rect y="0.0791016" width="390" height="52" fill="#F8F8FA"/>)}
    <path d="M214.593 92.0546L108.924 84.0791L45.082 162.948L42 216.561H103.2L106.282 226.752H181.131L184.213 246.69H259.062V267.072L274.032 281.251L255.54 337.079L348 270.174L298.688 267.072L278.435 251.121L259.062 165.606L242.771 158.074V135.92L210.63 116.424L214.593 92.0546Z" fill="#F8F8FA"/>
  </SvgIcon>);
};
