import React from 'react';
import { ToolTip } from '@groupby/ui-components';
import { useGetConfigPageType, useUserSettings } from '@/store/selectors';
import { SearchForRetail } from '@/constants';
import { AreaSelector } from '@/search-for-retail/generic/area-pages';
import { SiteSelector } from '@/search-for-retail/generic/site-pages';
import { CollectionSelector } from '@/search-for-retail/generic/collection-pages';
import { StyledButton, StyledContextSelectorsWrapper, StyledDefaultButton } from './context-selectors.styles';
import { useLocalization } from '@/localization';
import { useIsCreatingModel, useIsEditingModel } from '@/routing/hooks/utils';
// eslint-disable-next-line complexity
export const ContextSelectors = ({ collections, defaultCollection, selectedCollection, onSelectCollection, areas, defaultArea, selectedArea, onSelectArea, defaultSite, selectedSite, onSelectSite, isApplyButtonDisabled, handleApplyClicked, handleSetAsDefaultClicked, }) => {
    const configType = useGetConfigPageType();
    const { formatMessage: t } = useLocalization();
    const isEditing = useIsEditingModel();
    const isCreating = useIsCreatingModel();
    const settings = useUserSettings();
    const isPageAreaDependent = configType === SearchForRetail.PageType.AREA;
    const isPageSiteDependent = configType === SearchForRetail.PageType.SITE;
    const isSetDefaultDisabled = ((selectedArea && selectedCollection) && ((settings === null || settings === void 0 ? void 0 : settings.defaultArea) === (selectedArea === null || selectedArea === void 0 ? void 0 : selectedArea.name) && (settings === null || settings === void 0 ? void 0 : settings.defaultCollection) === (selectedCollection === null || selectedCollection === void 0 ? void 0 : selectedCollection.collection))) || (isPageAreaDependent && !selectedArea);
    const isSelectorGloballyDisabled = isEditing || isCreating;
    return configType !== SearchForRetail.PageType.GLOBAL && (<StyledContextSelectorsWrapper>
      <CollectionSelector collections={collections} disabled={isSelectorGloballyDisabled} defaultCollection={defaultCollection} onSelectCollection={onSelectCollection} selectedCollectionName={selectedCollection === null || selectedCollection === void 0 ? void 0 : selectedCollection.collection}/>
      {isPageAreaDependent && (<AreaSelector areas={areas} defaultArea={defaultArea} disabled={!(selectedCollection === null || selectedCollection === void 0 ? void 0 : selectedCollection.collection) || isSelectorGloballyDisabled} selectedAreaName={selectedArea === null || selectedArea === void 0 ? void 0 : selectedArea.name} onSelectArea={onSelectArea}/>)}
      {isPageSiteDependent && (<SiteSelector collectionName={selectedCollection === null || selectedCollection === void 0 ? void 0 : selectedCollection.collection} defaultSite={defaultSite} disabled={!(selectedCollection === null || selectedCollection === void 0 ? void 0 : selectedCollection.collection) || isSelectorGloballyDisabled} selectedSiteName={selectedSite === null || selectedSite === void 0 ? void 0 : selectedSite.name} onSelectSite={onSelectSite}/>)}
      <StyledButton rounded variant="contained" color="primary" disabled={isApplyButtonDisabled} title={t({ key: 'APPLY' })} onClick={handleApplyClicked}/>
      {isPageAreaDependent && (<ToolTip title={t({ key: 'SET_AS_DEFAULT_TOOLTIP' })} enterDelay={500} leaveDelay={50}>
          <StyledDefaultButton rounded variant="contained" disabled={isSetDefaultDisabled} title={t({ key: 'SET_AS_DEFAULT' })} onClick={handleSetAsDefaultClicked}/>
        </ToolTip>)}
    </StyledContextSelectorsWrapper>);
};
export default ContextSelectors;
