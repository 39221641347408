import { __awaiter } from "tslib";
import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import { useDispatch } from 'react-redux';
import { useLocalization } from '@/localization';
import { ContextSelector } from '@/search-for-retail/generic/page-context-selection';
import { getSiteShortList } from '@/search-for-retail/site/site.client';
import { useAuthToken } from '@/auth/hooks/authentication';
import { logError } from '@/utils/logger';
import { addError } from '@/store/actions/creators';
export const SiteSelector = ({ collectionName, disabled, selectedSiteName, defaultSite, onSelectSite, }) => {
    const dispatch = useDispatch();
    const token = useAuthToken();
    const { formatMessage: t } = useLocalization();
    const [loading, setLoading] = useState(true);
    const [sites, setSites] = useState([]);
    const loadSites = useCallback((collectionName) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            const siteShortList = yield getSiteShortList(token, collectionName);
            if (defaultSite) {
                const site = siteShortList.find(({ name }) => name === defaultSite);
                onSelectSite(site);
            }
            setSites(siteShortList);
        }
        catch (e) {
            const errorMessage = 'TASK_SITE_LOAD_ALL_ERROR';
            logError(errorMessage, e);
            dispatch(addError({ key: errorMessage }));
        }
        finally {
            setLoading(false);
        }
    }), [token, defaultSite, onSelectSite, dispatch]);
    useEffect(() => {
        void loadSites(collectionName);
    }, [loadSites, collectionName]);
    const handleSwitchSite = useCallback((name) => {
        const site = sites.find((value) => value.name === name);
        onSelectSite(site);
    }, [sites, onSelectSite]);
    const sitesOptions = useMemo(() => [...sites]
        .map(({ name }) => name)
        .sort((a, b) => a.localeCompare(b)), [sites]);
    return (<ContextSelector label={t({ key: 'SITE_SELECTION' })} selectedOption={selectedSiteName !== null && selectedSiteName !== void 0 ? selectedSiteName : ''} switchOption={handleSwitchSite} options={sitesOptions} defaultOption={defaultSite} primaryCopy={t({ key: 'SITE' })} defaultDropdownText={t({ key: 'SELECT_SITE_DROPDOWN' })} noOptionsText={t({ key: 'NO_SITE_AVAILABLE' })} disabled={disabled || loading}/>);
};
