import * as SessionReducers from './session';
import * as Actions from '@/store/actions/types';
export const DEFAULT = {
    isLoading: false,
    isInactive: false,
    collectionName: undefined,
    areaName: undefined,
    site: undefined,
    modelType: null,
    keys: {},
    tenantSettings: {
        preferredLanguage: undefined,
        auth0Region: undefined,
    },
    isLoadingAnalyticsDashboards: false,
    isAnalyticsComparisonModeEnabled: false,
    mostRecentlyUpdatedModelId: null,
};
export default (state = DEFAULT, action) => {
    switch (action.type) {
        case Actions.SWITCH_AREA:
            return SessionReducers.switchAreaReducer(state, action);
        case Actions.SWITCH_SITE:
            return SessionReducers.switchSiteReducer(state, action);
        case Actions.SWITCH_MODEL_TYPE:
            return SessionReducers.switchModelTypeReducer(state, action);
        case Actions.SET_LOADING:
            return SessionReducers.setLoadingReducer(state, action);
        case Actions.SET_INACTIVE:
            return SessionReducers.setInactiveReducer(state, true);
        case Actions.SET_ACTIVE:
            return SessionReducers.setInactiveReducer(state, false);
        case Actions.ACKNOWLEDGE_AUTHENTICATION:
        case Actions.CLEAR_AUTHENTICATION:
            return SessionReducers.setInactiveReducer(state, false);
        case Actions.S4R_UPDATE_TENANT_SETTINGS:
            return SessionReducers.updateTenantSettingsReducer(state, action);
        case Actions.S4R_SET_LOADING_ANALYTICS_DASHBOARDS:
            return SessionReducers.setLoadingAnalyticsDashboardsReducer(state, action);
        case Actions.S4R_SET_COLLECTION_NAME:
            return SessionReducers.setCollectionName(state, action);
        case Actions.SET_IS_ANALYTICS_COMPARISON_MODE_ENABLED:
            return SessionReducers.setIsAnalyticsComparisonModeEnabledReducer(state, action);
        case Actions.SET_MOST_RECENTLY_UPDATED_MODEL:
            return SessionReducers.setMostRecentlyUpdatedModel(state, action);
        default:
            return state;
    }
};
export function switchAreaReducer(state, { payload: areaName }) {
    return Object.assign(Object.assign({}, state), { areaName });
}
export function switchSiteReducer(state, { payload: site }) {
    return Object.assign(Object.assign({}, state), { site });
}
export function switchModelTypeReducer(state, { payload: modelType }) {
    return Object.assign(Object.assign({}, state), { modelType });
}
export function setLoadingReducer(state, { payload: isLoading }) {
    return Object.assign(Object.assign({}, state), { isLoading });
}
export function setInactiveReducer(state, isInactive) {
    return Object.assign(Object.assign({}, state), { isInactive });
}
export function updateTenantSettingsReducer(state, { payload: { tenantSettings } }) {
    return Object.assign(Object.assign({}, state), { tenantSettings });
}
export function setLoadingAnalyticsDashboardsReducer(state, { payload: isLoadingAnalyticsDashboards }) {
    return Object.assign(Object.assign({}, state), { isLoadingAnalyticsDashboards });
}
export function setCollectionName(state, { payload: collectionName }) {
    return Object.assign(Object.assign({}, state), { collectionName });
}
export const setIsAnalyticsComparisonModeEnabledReducer = (state, { payload: isAnalyticsComparisonModeEnabled }) => (Object.assign(Object.assign({}, state), { isAnalyticsComparisonModeEnabled }));
export const setMostRecentlyUpdatedModel = (state, { payload: { modelId, location } }) => {
    let mostRecentlyUpdatedModelPage = null;
    if (location) {
        const locationPageQuery = location.split('?')[1];
        const query = new URLSearchParams(locationPageQuery);
        mostRecentlyUpdatedModelPage = {
            page: Number(query.get('page')) || null,
            size: Number(query.get('size')) || null,
            sort: query.get('sort') || null,
            order: query.get('order') || null,
        };
    }
    return (Object.assign(Object.assign({}, state), { mostRecentlyUpdatedModelId: modelId, mostRecentlyUpdatedModelPage }));
};
