import { ENRICH_DRAWER_SECTION } from '@groupby/enrich-console';
import { ModelType, Role, Routing } from '@/constants';
import { hasEnrichAccessOnly } from '@/config/utils';
import { NavigationType } from '@/navigation/types';
const MERCHANDISING_SECTION = {
    label: 'MERCHANDISING',
    path: Routing.SectionPath.MERCHANDISING,
    disabled: hasEnrichAccessOnly,
    categories: [
        {
            label: 'DRAWER_CATEGORY_BUILD_CAMPAIGNS',
            routes: [
                {
                    name: ModelType.RULE,
                    path: Routing.MODEL_PATHS[ModelType.RULE],
                    sectionPath: Routing.SectionPath.MERCHANDISING,
                    label: 'RULES',
                    roles: [Role.MERCHANDISING],
                },
                {
                    name: ModelType.NAVIGATION,
                    path: Routing.MODEL_PATHS[ModelType.NAVIGATION],
                    sectionPath: Routing.SectionPath.MERCHANDISING,
                    label: 'NAVIGATIONS',
                    roles: [Role.ENGINE_CONFIGURATION],
                },
                {
                    name: ModelType.PRODUCT_RECOMMENDATIONS,
                    path: Routing.MODEL_PATHS[ModelType.PRODUCT_RECOMMENDATIONS],
                    sectionPath: Routing.SectionPath.MERCHANDISING,
                    label: 'PRODUCT_RECOMMENDATIONS',
                    roles: [Role.MERCHANDISING],
                    subroutes: [
                        {
                            name: ModelType.RECS_MODEL,
                            path: Routing.MODEL_PATHS[ModelType.RECS_MODEL],
                            sectionPath: Routing.SectionPath.MERCHANDISING,
                            label: 'DRAWER_ITEM_MODEL_MANAGEMENT',
                            roles: [Role.MERCHANDISING],
                        },
                        {
                            name: ModelType.FILTER_SETS,
                            path: Routing.MODEL_PATHS[ModelType.FILTER_SETS],
                            sectionPath: Routing.SectionPath.MERCHANDISING,
                            label: 'FILTER_SETS',
                            roles: [Role.MERCHANDISING],
                        },
                    ],
                },
            ],
        },
        {
            label: 'DRAWER_CATEGORY_MANAGE_PRODUCT_CATALOG',
            routes: [
                {
                    name: ModelType.ATTRIBUTES,
                    path: Routing.MODEL_PATHS[ModelType.ATTRIBUTES],
                    sectionPath: Routing.SectionPath.MERCHANDISING,
                    label: 'ATTRIBUTE_MANAGEMENT',
                    roles: [Role.MERCHANDISING],
                },
                {
                    name: ModelType.CATEGORIES,
                    path: Routing.MODEL_PATHS[ModelType.CATEGORIES],
                    sectionPath: Routing.SectionPath.MERCHANDISING,
                    label: 'CATEGORIES',
                    roles: [Role.MERCHANDISING],
                },
            ],
        },
        {
            label: 'DRAWER_CATEGORY_TUNE_THE_ENGINE',
            routes: [
                {
                    name: ModelType.BIASING_PROFILE,
                    path: Routing.MODEL_PATHS[ModelType.BIASING_PROFILE],
                    sectionPath: Routing.SectionPath.MERCHANDISING,
                    label: 'BIASING',
                    roles: [Role.ENGINE_CONFIGURATION],
                },
                {
                    name: ModelType.LINGUISTIC_CONTROLS,
                    path: Routing.MODEL_PATHS[ModelType.LINGUISTIC_CONTROLS],
                    sectionPath: Routing.SectionPath.MERCHANDISING,
                    label: 'LINGUISTIC_CONTROLS',
                    roles: [Role.MERCHANDISING],
                },
                {
                    name: ModelType.REDIRECT,
                    path: Routing.MODEL_PATHS[ModelType.REDIRECT],
                    sectionPath: Routing.SectionPath.MERCHANDISING,
                    label: 'REDIRECTS',
                    roles: [Role.QUERY_REWRITE],
                },
                {
                    name: ModelType.EVALUATE,
                    path: Routing.MODEL_PATHS[ModelType.EVALUATE],
                    sectionPath: Routing.SectionPath.MERCHANDISING,
                    drawerStateOverrides: {
                        navigationType: NavigationType.PRIMARY,
                        isDrawerOpen: false,
                    },
                    label: 'EVALUATION_TOOL',
                    roles: [Role.ENGINE_CONFIGURATION],
                },
            ],
        },
        {
            label: 'DRAWER_CATEGORY_CREATE_CONTENT',
            routes: [
                {
                    name: ModelType.ZONE,
                    path: Routing.MODEL_PATHS[ModelType.ZONE],
                    sectionPath: Routing.SectionPath.MERCHANDISING,
                    label: 'ZONES',
                    roles: [Role.MERCHANDISING],
                },
                {
                    name: ModelType.TEMPLATE,
                    path: Routing.MODEL_PATHS[ModelType.TEMPLATE],
                    sectionPath: Routing.SectionPath.MERCHANDISING,
                    label: 'TEMPLATES',
                    roles: [Role.MERCHANDISING],
                },
                {
                    name: ModelType.PREVIEW_WRAPPER,
                    path: Routing.MODEL_PATHS[ModelType.PREVIEW_WRAPPER],
                    sectionPath: Routing.SectionPath.MERCHANDISING,
                    label: 'PREVIEW_WRAPPER',
                    roles: [Role.MERCHANDISING],
                },
            ],
        },
        {
            label: 'DRAWER_CATEGORY_CONFIGURE_PLATFORM',
            routes: [
                {
                    name: ModelType.TAG,
                    path: Routing.MODEL_PATHS[ModelType.TAG],
                    sectionPath: Routing.SectionPath.MERCHANDISING,
                    label: 'TAG_MANAGEMENT',
                    roles: [Role.MERCHANDISING],
                },
                {
                    name: ModelType.SITE,
                    path: Routing.MODEL_PATHS[ModelType.SITE],
                    sectionPath: Routing.SectionPath.MERCHANDISING,
                    label: 'SITE_MANAGEMENT',
                    roles: [Role.MERCHANDISING],
                },
            ],
        },
    ],
};
const getAnalyticsSection = ({ isNewSearchPerformanceDashboard, isDataQuality }) => ({
    label: 'ANALYTICS',
    path: Routing.SectionPath.ANALYTICS,
    hasLazyLoadedRoutes: true,
    disabled: hasEnrichAccessOnly,
    categories: [
        {
            label: 'DRAWER_CATEGORY_ENHANCE_PERFORMANCE',
            routes: [
                {
                    name: ModelType.ANALYTICS,
                    path: Routing.MODEL_PATHS[ModelType.ANALYTICS],
                    sectionPath: Routing.SectionPath.ANALYTICS,
                    label: 'ANALYTICS',
                    roles: [Role.MERCHANDISING],
                },
                ...(isNewSearchPerformanceDashboard ? [
                    {
                        name: ModelType.SEARCH_PERFORMANCE,
                        path: Routing.MODEL_PATHS[ModelType.SEARCH_PERFORMANCE],
                        sectionPath: Routing.SectionPath.ANALYTICS,
                        label: 'SEARCH_PERFORMANCE',
                        roles: [Role.MERCHANDISING],
                    },
                ] : []),
            ],
        },
        ...(isDataQuality ? [
            {
                label: 'DRAWER_CATEGORY_CHECK_SITE_HEALTH',
                routes: [
                    {
                        name: ModelType.SEARCH_DATA_QUALITY,
                        path: Routing.MODEL_PATHS[ModelType.SEARCH_DATA_QUALITY],
                        sectionPath: Routing.SectionPath.ANALYTICS,
                        label: 'SEARCH_DATA_QUALITY',
                        roles: [Role.ADMIN, Role.MERCHANDISING],
                    },
                ],
            },
        ] : []),
        {
            label: 'PIPELINE_HEALTH',
            routes: [
                {
                    name: ModelType.PIPELINE_HEALTH,
                    path: Routing.MODEL_PATHS[ModelType.PIPELINE_HEALTH],
                    sectionPath: Routing.SectionPath.ANALYTICS,
                    label: 'PIPELINE_HEALTH',
                    roles: [Role.ADMIN, Role.MERCHANDISING],
                },
            ],
        },
    ],
});
const ADMIN_SECTION = {
    label: 'ADMIN',
    path: Routing.SectionPath.ADMIN,
    categories: [
        {
            label: 'DRAWER_CATEGORY_MANAGE_PEOPLE',
            routes: [
                {
                    name: ModelType.USER,
                    path: Routing.MODEL_PATHS[ModelType.USER],
                    sectionPath: Routing.SectionPath.ADMIN,
                    label: 'USER_MANAGEMENT',
                    roles: [Role.ADMIN],
                },
            ],
        },
        {
            label: 'DRAWER_CATEGORY_CONFIGURE_PLATFORM',
            routes: [
                {
                    name: ModelType.INGESTION_TASKS,
                    path: Routing.MODEL_PATHS[ModelType.INGESTION_TASKS],
                    sectionPath: Routing.SectionPath.ADMIN,
                    label: 'INGESTION_TASKS',
                    roles: [Role.ADMIN],
                    disabled: hasEnrichAccessOnly,
                },
                {
                    name: ModelType.AREA,
                    path: Routing.MODEL_PATHS[ModelType.AREA],
                    sectionPath: Routing.SectionPath.ADMIN,
                    label: 'AREA_MANAGEMENT',
                    roles: [Role.ADMIN],
                    disabled: hasEnrichAccessOnly,
                },
                {
                    name: ModelType.IMAGE_PATH,
                    path: Routing.MODEL_PATHS[ModelType.IMAGE_PATH],
                    sectionPath: Routing.SectionPath.ADMIN,
                    label: 'IMAGE_PATH',
                    roles: [Role.ADMIN],
                    disabled: hasEnrichAccessOnly,
                },
                {
                    name: ModelType.AUTOCOMPLETE,
                    path: Routing.MODEL_PATHS[ModelType.AUTOCOMPLETE],
                    sectionPath: Routing.SectionPath.ADMIN,
                    label: 'AUTOCOMPLETE',
                    roles: [Role.ADMIN],
                    disabled: hasEnrichAccessOnly,
                },
            ],
        },
    ],
};
const SEO_STUDIO_SECTION = [{
        label: 'SEO_STUDIO',
        path: Routing.SectionPath.SEO_STUDIO,
        categories: [
            {
                label: 'SEO_STUDIO_MANAGEMENT',
                routes: [
                    {
                        name: ModelType.SEO_DASHBOARD,
                        path: Routing.MODEL_PATHS[ModelType.SEO_DASHBOARD],
                        sectionPath: Routing.SectionPath.SEO_STUDIO,
                        label: 'SEO_STUDIO_DASHBOARD',
                        roles: [Role.ADMIN],
                    },
                    {
                        name: ModelType.KEYWORD_SUGGESTIONS,
                        path: Routing.MODEL_PATHS[ModelType.KEYWORD_SUGGESTIONS],
                        sectionPath: Routing.SectionPath.SEO_STUDIO,
                        label: 'KEYWORDS',
                        roles: [Role.ADMIN],
                    },
                    {
                        name: ModelType.SITEMAP,
                        path: Routing.MODEL_PATHS[ModelType.SITEMAP],
                        sectionPath: Routing.SectionPath.SEO_STUDIO,
                        label: 'SEO_STUDIO_SITEMAP',
                        roles: [Role.ADMIN],
                    },
                ],
            },
        ],
    }];
export const createDrawerConfig = ({ isSeoStudio, isDataQuality, isNewSearchPerformanceDashboard, }) => {
    const drawerConfig = {
        sections: [
            MERCHANDISING_SECTION,
            getAnalyticsSection({ isNewSearchPerformanceDashboard, isDataQuality }),
            ADMIN_SECTION,
            ...(isSeoStudio ? SEO_STUDIO_SECTION : []),
            Object.assign(Object.assign({}, ENRICH_DRAWER_SECTION), { showAlways: true }),
        ],
    };
    return drawerConfig;
};
