import { __awaiter } from "tslib";
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth0Token } from './authentication';
import { convertS4RRoleToMerchandisingRoles } from '@/utils';
import { getUserProfile } from '@/search-for-retail/user/user.client';
import { extractAreaPermissions, getUserRoles } from '@/pages/login/utils';
import { acknowledgeAuthentication, setUserSettings } from '@/store/actions/creators';
export const useRefreshUserProfile = () => {
    const { getToken } = useAuth0Token();
    const dispatch = useDispatch();
    const refreshUserProfile = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const accessToken = yield getToken('off');
        const userProfile = yield getUserProfile(accessToken);
        // Derive role from permissions
        const { permissions } = userProfile;
        const userRole = getUserRoles(permissions);
        // derive areaPermissions data from permissions
        const auth0User = Object.assign(Object.assign({}, userProfile), { areaPermissions: extractAreaPermissions(permissions), role: userRole });
        const merchandisingRoles = convertS4RRoleToMerchandisingRoles(userRole);
        dispatch(setUserSettings(auth0User === null || auth0User === void 0 ? void 0 : auth0User.settings));
        dispatch(acknowledgeAuthentication(accessToken, Object.assign(Object.assign({}, auth0User), { allowedAccess: merchandisingRoles })));
        return auth0User;
    }), [getToken, dispatch]);
    return refreshUserProfile;
};
export default useRefreshUserProfile;
