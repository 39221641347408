import * as Actions from '../types/session';
import { createAction } from '../utils';
export const switchArea = (area, force) => createAction(Actions.SWITCH_AREA, area, ...(force ? [{ force }] : []));
export const switchSite = (site, force) => createAction(Actions.SWITCH_SITE, site, ...(force ? [{ force }] : []));
export const switchModelType = (modelType) => createAction(Actions.SWITCH_MODEL_TYPE, modelType);
export const setLoading = (loading) => createAction(Actions.SET_LOADING, loading);
export const setInactive = () => createAction(Actions.SET_INACTIVE);
export const setCollectionName = (collectionName) => createAction(Actions.S4R_SET_COLLECTION_NAME, collectionName);
export const setIsAnalyticsComparisonModeEnabled = (isAnalyticsComparisonModeEnabled) => createAction(Actions.SET_IS_ANALYTICS_COMPARISON_MODE_ENABLED, isAnalyticsComparisonModeEnabled);
export const setMostRecentlyUpdatedModel = (modelId, location) => {
    const payload = { modelId };
    if (location)
        payload.location = location;
    return (createAction(Actions.SET_MOST_RECENTLY_UPDATED_MODEL, payload));
};
